<template>
  <a-input-number
    id="inputNumber"
    v-model="formData[valueKey]"
    :class="[{'noStep': noStep}, {'noBorder': noBorder}]"
    :placeholder="placeholder"
    :min="min"
    :max="max"
    :step="step"
    @change="onChange"
  />
</template>

<script>
export default {
  props: {
    formData: {
      type: Object,
      default: () => ({}),
    },
    valueKey: {
      type: String,
      default: 'value',
    },
    min: {
      type: [Number, String],
      default: -Infinity,
    },
    max: {
      type: [Number, String],
      default: Infinity,
    },
    step: {
      type: [Number, String],
      default: 1,
    },
    placeholder: {
      type: String,
      default: '',
    },
    noStep: {
      type: Boolean,
      default: true,
    },
    noBorder: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {}
  },
  methods: {
    onChange(value) {
      console.log('onChange', value)
      console.log(this.formData)
    },
  },
}
</script>

<style scoped lang="less">
.unset {
  border-radius: 0;
  outline: unset;
  box-shadow: unset;
  border: unset;
}

.ant-input-number {
  width: 100%;
}
.noStep {
  /deep/ .ant-input-number-handler-wrap {
    display: none;
  }
}
.noBorder {
  .unset();
  &:hover,
  &:focus,
  &:active {
    .unset();
  }
}
</style>