var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("a-input-number", {
    class: [{ noStep: _vm.noStep }, { noBorder: _vm.noBorder }],
    attrs: {
      id: "inputNumber",
      placeholder: _vm.placeholder,
      min: _vm.min,
      max: _vm.max,
      step: _vm.step,
    },
    on: { change: _vm.onChange },
    model: {
      value: _vm.formData[_vm.valueKey],
      callback: function ($$v) {
        _vm.$set(_vm.formData, _vm.valueKey, $$v)
      },
      expression: "formData[valueKey]",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }